
import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    emits: ['reload'],
    data() {
        return {
            item: {
                translations: {
                    name: {},
                },
                code: '',
                platform: undefined as any,
                platform_id: null,
            },
        };
    },
    methods: {
        createItem(): void {
            this.post('products', {
                ...this.item,
                platform_id: this.item.platform ? this.item.platform.id : null,
                name: this.item.translations.name,
            })
                .then(() => {
                    this.$router.push({ name: 'products.list' });
                    this.$emit('reload');
                });
        },

    },
});
